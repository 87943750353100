
// Projects Page
function filter_query(mode) {

    // console.log('Mode',mode);

    if(mode == 'popstate') {

        var service_slug = getParameterByName('service');
        // console.log(service_slug);
        // var filter_slug = getParameterByName('filter');
        // console.log(type_slug);
        var pg = getParameterByName('pg');

        // Find and set service
        var matching_service = jQuery('#service input[id="' + service_slug + '"]');
        // console.log(matching_service);
        if (matching_service.length == 1) {
            matching_service.prop("checked", true);

            jQuery('.stats-projects .stats').addClass('hide');
            jQuery('.stats-projects .stats.'+service_slug).removeClass('hide');

        }

        // // Find and set filter
        // var matching_filter = jQuery('#filter input[id="' + filter_slug + '"]');
        // // console.log(matching_filter);
        // if(matching_filter.length == 1) {
        //     matching_filter.prop("checked", true);
        // }

        
    }

    var api_url = '/wp-json/wp/v2/filter_projects/';
    var url_path = '/projects/?search';


    var service_id = jQuery('.filter-selectors #service input:checked').attr('data-id');
    var service_slug = jQuery('.filter-selectors #service input:checked').attr('data-slug');
    // console.log('service_id', service_id);
    // console.log('service_slug', service_slug);
    if (service_slug) {
        url_path += '&service=' + service_slug;
    }

    // var filter_slug = jQuery('.filter-selectors #filter input:checked').attr('data-slug');
    // // console.log('service_slug', filter);
    // if(filter_slug) {
    //     url_path += '&filter='+filter_slug;
    // }


    
    if (!service_slug) {
        url_path = '/projects/';
    }
    

    jQuery("#projects-search").slideDown(400);
    jQuery("#projects-search .img-completed").removeClass('show');
    jQuery("#projects-search .img-nothing").removeClass('show');
    jQuery("#projects-search .img-searching").addClass('show');
    jQuery("#projects-search .search-status").text('Searching'); 

    // console.log('Product URL Slug',url_path);

    jQuery.ajax({
        type: 'GET',
        url: api_url,
        data: {
            service: service_slug,
            service_slug: service_slug,    
            // filter: filter_slug,
            // filter_slug: filter_slug,   
            pg: pg
        },
        success: function(data){

        if(mode != 'popstate') {
            history.pushState({page:url_path}, null, url_path);
        }

          var response_data = data;

            if(response_data == 'No Results Found') {
                jQuery("#projects-search .img-nothing").addClass('show');
                jQuery("#projects-search .img-searching").removeClass('show');
                jQuery("#projects-search .search-status").text('No Results Found');
                jQuery('#projects-results').html('').foundation();
            } else {
                jQuery("#projects-search .img-nothing").removeClass('show');
                jQuery("#projects-search .img-searching").removeClass('show');
                jQuery("#projects-search .img-completed").addClass('show');
                jQuery("#projects-search .search-status").text('Loading Results');
                
                setTimeout(function(){
                    jQuery("#projects-search .img-completed").removeClass('show');
                    jQuery('#projects-results').html(data).foundation();
                    AOS.refresh();
                    jQuery("#projects-search").slideUp(400);
                },250);
            }

        }
      });

}


jQuery(document).ready(function() {

    if(jQuery('#projects-results').length > 0) {

        // jQuery('#filter input:radio, #filter label').mouseup(function (e) {
        //     jQuery('#filter input:radio, #filter label').not(this).prop('checked', false);
        // });

        jQuery('#service input:radio, #service label').mouseup(function (e) {
            jQuery('#service input:radio, #service label').not(this).prop('checked', false);
            service_value = jQuery(this).parent().find('input').data('stats');
            // console.log(service_value);
            jQuery('.stats-projects .stats').addClass('hide');
            jQuery('.stats-projects .stats.'+service_value).removeClass('hide');
        });

        jQuery('#service').on('change', function(e) {
            
            filter_query('mouseup');
            // var desiredHeight = $(window).height() + 100;
            // jQuery("html").animate({ scrollTop: jQuery("h2.hero-search").offset().top - 100 }, "slow");
        });
        jQuery('#all').click(function(e){
            filter_query('reset');
            history.pushState({ page: '/projects/' }, null, '/projects/');
        });

        window.addEventListener('popstate', function(event) {
            filter_query('popstate');
        });

        if(getParameterByName('service') ) {
            filter_query('popstate');
        }


    }


});

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
