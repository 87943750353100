/*
|==========================================================================
| Items in Viewport
|==========================================================================
| 
| This function detects if an item is in the viewport and could perhaps
| be improved upon.
|
|==========================================================================
| @reference: https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
| 
*/

// Items in Viewport
// function items_in_viewport(item_selector) {

//   if(jQuery(item_selector).length > 0) {

//     if(item_selector === ".video-block iframe") {

//       var iframe = jQuery(item_selector);
//       var player = new Vimeo.Player(iframe);

//       jQuery(item_selector+":above-the-top").each(function(index) {
//         player.pause();
//       });

//       jQuery(item_selector+":in-viewport").each(function(index) {
//         player.play();
//       });

//       jQuery(item_selector+":below-the-fold").each(function(index) {
//         player.pause();
//       });

//     } else {

//       jQuery(item_selector+":above-the-top").each(function(index) {
//             // jQuery(this).removeClass(animationIn);
//             // jQuery(this).addClass(animationOut);
//           });

//       jQuery(item_selector+":in-viewport").each(function(index) {
//         jQuery(this).removeClass(animationOut);
//         jQuery(this).addClass(animationIn);
//       });

//       jQuery(item_selector+":below-the-fold").each(function(index) {
//             // jQuery(this).removeClass(animationIn);
//             // jQuery(this).addClass(animationOut);
//           });

//     }

//   }

// }

const isInViewport = function (elem) {
  const distance = elem.getBoundingClientRect();
  // console.log(distance.bottom);
	return (
		distance.top >= 0 &&
		distance.left >= 0 &&
		distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		distance.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

const isOutOfViewport = function (elem) {

	// Get element's bounding
	const bounding = elem.getBoundingClientRect();

	// Check if it's out of the viewport on each side
	const out = {};
	out.top = bounding.top < 0;
	out.left = bounding.left < 0;
	out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
	out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

	return out;

};

const mql = window.matchMedia("screen and (max-width: 1023px)");

let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;

// Play video on scroll SU Case
if(jQuery('.video-block').length > 0 && !mql.matches && !isIOS) {

  var videos = document.getElementsByTagName("video");

  function checkScroll(e) {
      
      var fraction = 0.8; // Play when 80% of the player is visible.

      for(var i = 0; i < videos.length; i++) {

          var video = videos[i];

          // console.log(video);

          var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
              b = y + h, //bottom
              visibleX, visibleY, visible;

              visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
              visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));


              visible = visibleX * visibleY / (w * h);

              if (visible > fraction) {

                video.play();
                  
              } else {
                  video.pause();
              }

      }

  }

  window.addEventListener('scroll', checkScroll, false);
  window.addEventListener('resize', checkScroll, false);

}

if(jQuery('.page-template-template-services').length > 0) {

  function isInView(elem){
    return jQuery(elem).offset().top - jQuery(window).scrollTop() < jQuery(elem).height();
  }
    jQuery(".video-container").each(function (index, elem) { 
    var hasReachedUserExperience = false;
    jQuery(window).scroll(function() {
        if (isInView(jQuery(elem)) && !hasReachedUserExperience) {
            hasReachedUserExperience = true;
            jQuery('video', elem).get(0).play();
        }
    })
  });

  document.getElementById('video_2').addEventListener('ended',endVideo_2,false);
  document.getElementById('video_3').addEventListener('ended',endVideo_3,false);
  document.getElementById('video_4').addEventListener('ended',endVideo_4,false);
  document.getElementById('video_5').addEventListener('ended',endVideo_5,false);
  document.getElementById('video_6').addEventListener('ended',endVideo_6,false);

  jQuery('.video-container button[data-play]').click(function(event) {
    jQuery(this).addClass('hide');
    jQuery(this).parent().find('button[data-pause]').removeClass('hide');
    var video = jQuery(this).parent().parent().find('video');
    video.get(0).play();
    // console.log('click play');
  });
  jQuery('.video-container button[data-pause]').click(function(event) {
    jQuery(this).addClass('hide');
    jQuery(this).parent().find('button[data-play]').removeClass('hide');
    var video = jQuery(this).parent().parent().find('video');
    video.get(0).pause();
    // console.log('click pause');
  });


}

// Check if scrollbar overlap footer
if(jQuery('#scrollBar').length > 0) {

  // The checker
  const gambitIsInView = el => {
    const scroll = window.scrollY || window.pageYOffset
    const boundsTop = el.getBoundingClientRect().top + scroll + 59
    
    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    }
    
      const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
    }
    
    return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom ) 
      || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
  }


  // Usage.
  document.addEventListener( 'DOMContentLoaded', () => {
    const footer = document.querySelector( '#footer' )
    
    const handler = () => raf( () => {
      if (gambitIsInView( footer )) {
        // console.log('In the viewport! =)');
        jQuery('.services-filter,.chapter-filter').addClass('noshow');
      } else {
        // console.log('Not in the viewport! =(');
        jQuery('.services-filter,.chapter-filter').removeClass('noshow');
      }
    } )
    
    handler()
    window.addEventListener( 'scroll', handler )
  } )

  const raf = 
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function( callback ) {
        window.setTimeout( callback, 1000 / 60 )
    }

}



// Change background color to header and menu bar
if(jQuery('.hero.bg-black').length > 0) {

  var elem = document.querySelector(".bg-black");

  if(isOverlap(elem)) {
    jQuery('header.header').addClass('bgblack');
  } else {
    jQuery('header.header').removeClass('bgblack');
  }

  window.addEventListener('scroll', function (event) {
    changeHeaderBG();
  }, false);

}

function changeHeaderBG(){

  if(isOverlap(document.querySelector(".hero.bg-black"))) {
    jQuery('header.header').addClass('bgblack');
  } else if(isOverlap(document.querySelector("#apps"))) {
    jQuery('header.header').addClass('bgblack');
  } else if(isOverlap(document.querySelector("#websites"))) {
    jQuery('header.header').addClass('bgblack');
  } else {
    jQuery('header.header').removeClass('bgblack');
  }

}

function isOverlap(el){
      // console.log(el)
      let b = el.getBoundingClientRect(),
      a = document.querySelector('header.header').getBoundingClientRect();
    if (b.top <= a.top + a.height && b.top + b.height > a.top) {
      // console.log('overlap');
      return true;
    } else {
      // console.log('dont overlap');
      return false;
    }
      
}

function endVideo_2(e) {
  jQuery('#video_2').parent().find('button[data-play]').removeClass('hide');
  jQuery('#video_2').parent().find('button[data-pause]').addClass('hide');
}

function endVideo_3(e) {
  jQuery('#video_3').parent().find('button[data-play]').removeClass('hide');
  jQuery('#video_3').parent().find('button[data-pause]').addClass('hide');
}

function endVideo_4(e) {
  jQuery('#video_4').parent().find('button[data-play]').removeClass('hide');
  jQuery('#video_4').parent().find('button[data-pause]').addClass('hide');
}

function endVideo_5(e) {
  jQuery('#video_5').parent().find('button[data-play]').removeClass('hide');
  jQuery('#video_5').parent().find('button[data-pause]').addClass('hide');
}

function endVideo_6(e) {
  jQuery('#video_6').parent().find('button[data-play]').removeClass('hide');
  jQuery('#video_6').parent().find('button[data-pause]').addClass('hide');
}