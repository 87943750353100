/*
|==========================================================================
| Isotope Masonry Grid
|==========================================================================
| 
| This function activates a grid based masonry layout that can be
| customized per project using the Isotope plugin.
|
|==========================================================================
| @reference: https://isotope.metafizzy.co/
|
*/

// Activates grid based masonry layout
function isotope_masonry() {

  jQuery('.grid').isotope({
    itemSelector: '.grid-item',
    percentPosition:true,
    masonry: { 
      columnWidth: '.grid-sizer'
    }
  }); 

  jQuery('.grid-item').click(function(event){
    var this_grid_item = jQuery(this);
    var link_to_go_to = this_grid_item.find('a').attr('href');
    external_redirect(link_to_go_to);
    load_content(link_to_go_to);
  }); 

  jQuery('.filter-button-group').on( 'click', 'button', function() {
    var filterValue = jQuery(this).attr('data-filter');
    jQuery('.grid').isotope({ filter: filterValue });
  });

  setTimeout(function(){
    jQuery("#showallposts").click();
  }, 350);
}