/*
|==========================================================================
| Hero Video
|==========================================================================
| 
| This function defines a custom hero video implementation.
|
|==========================================================================
|
*/

// Hero Video Variables
// var min_w = 768; 
// var vid_w_orig;
// var vid_h_orig;

jQuery(document).ready(function() { // DOM

  // // Hero Video init
  // vid_w_orig = parseInt(jQuery('.hero-video .responsive_video_container video').attr('width')); 
  // vid_h_orig = parseInt(jQuery('.hero-video .responsive_video_container video').attr('height'));   
  
  // setTimeout(function(){
  //   resize_hero_video();
  // },250);

  // // Fade In Home Page Video
  // setTimeout(function(){
  //   jQuery(".hero-video .responsive_video_container").addClass('visible');
  // },1000);


}); // DOM

// Hero Video init/resize
function resize_hero_video() {

    // set the video viewport to the window size
    jQuery('.responsive_video_container').width(jQuery(window).width());
    jQuery('.responsive_video_container').height(jQuery(window).height());

    // use largest scale factor of horizontal/vertical
    var scale_h = jQuery('.responsive_video_overflow').width() / vid_w_orig;
    var scale_v = jQuery('.responsive_video_overflow').height() / vid_h_orig;
    var scale = scale_h > scale_v ? scale_h : scale_v;

    // don't allow scaled width < minimum video width
    if (scale * vid_w_orig < min_w) {scale = min_w / vid_w_orig;}

    // now scale the video
    jQuery('.responsive_video_container video').width(scale * vid_w_orig);
    jQuery('.responsive_video_container video').height(scale * vid_h_orig);

    // and center it by scrolling the video viewport
    jQuery('.responsive_video_container').scrollLeft((jQuery('.responsive_video_container video').width() - jQuery(window).width()) / 2);
    jQuery('.responsive_video_container').scrollTop((jQuery('.responsive_video_container video').height() - jQuery(window).height()) / 2);

}