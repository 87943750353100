/*
|==========================================================================
| Device Detection Scripts
|==========================================================================
| 
| Various functions for helpful device detection.
|
|==========================================================================
|
*/

function detectBrowserAndDevice() {

  /* Alias for document.body.classList.add() */
  function addCls(cls) {
    document.body.classList.add(cls);
  }

  var ua = window.navigator.userAgent;

  var is_ios = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var is_webkit = !!ua.match(/WebKit/i);
  var is_ios_safari = is_ios && is_webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);

  var is_chrome = window.chrome;
  var is_explorer = ua.indexOf('MSIE') > -1;
  var is_firefox = ua.indexOf('Firefox') > -1;
  var is_safari = ua.indexOf("Safari") > -1;
  var is_edge = ua.indexOf("Edge") > -1;
  var is_opera = ua.toLowerCase().indexOf("op") > -1;
  var is_apple_device = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
  var is_native_android = ((ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1) && (ua.indexOf('Version') > -1));

  var is_ie_10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
  var is_ie_11 = ua.indexOf("Trident") !== -1 && ua.indexOf("rv:11") !== -1;

  if ((is_chrome)&&(is_safari)) { is_safari = false; }
  if ((is_chrome)&&(is_opera)) { is_chrome = false; }

  /* IE10 does not support classList so we use className */
  if (is_ie_10) {
    document.documentElement.className += 'ie-10';
  }

  /* Prevent IE10 from erroring by exiting early */
  if (!is_ie_10) {

    if (is_apple_device) {
      addCls('apple-device');
    }

    if ((is_native_android) && (!is_chrome)) {
      addCls('native-android-browser');
    }  

    if (is_edge) {
      addCls('edge');
    } else if (is_ie_11) {
      addCls('ie-11-or-newer');
    } else if (is_chrome) {
      addCls('chrome');
    } else if (is_firefox) {
      addCls('firefox');
    } else if (is_safari) {
      addCls('safari');
    } else if (is_opera) {
      addCls('opera');
    } else if (is_explorer) {
      addCls('ie');
    }

  }

}