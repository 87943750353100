/*
|==========================================================================
| GSAP Grid SVG Animation
|==========================================================================
| 
| This function requires GSAP to be installed to be valuable, as it
| utilizes functions used in their library.
|
|==========================================================================
| @reference: https://greensock.com/
|
*/

// Grid item SVG animation
function iconmenu_svg_animation() {

  var path_selector = '.replaced-svg path, .replaced-svg polygon, .replaced-svg rect, .replaced-svg circle, .replaced-svg polyline';

  jQuery("#menu-main-menu li a").hover(function(event) {

  //jQuery('.icon-menu-svg').hover(function(event) {
    var svg_found = jQuery(this).find(path_selector);
    if(svg_found.length > 0) {
      TweenLite.to(svg_found, 0, {drawSVG:"0% 0%"});
      setTimeout(function(){
        TweenLite.to(svg_found, 1.5, {drawSVG:true});
      },200);
    }
  },function(event){
    var svg_found = jQuery(this).find(path_selector);
    if(svg_found.length > 0) {
      TweenLite.to(svg_found, 0.5, {drawSVG:true});
    }
  });

}
