// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round( animationDuration / frameDuration );
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * ( 2 - t );

// The animation function, which takes an Element
const animateCountUp = el => {
    // console.log(el);
    let frame = 0;
    const countTo = parseInt( el.innerHTML, 10 );
    // Start the animation running 60 times per second
    const counter = setInterval( () => {
        frame++;
        // Calculate our progress as a value between 0 and 1
        // Pass that value to our easing function to get our
        // progress on a curve
        const progress = easeOutQuad( frame / totalFrames );
        // Use the progress value to calculate the current count
        const currentCount = Math.round( countTo * progress );

        // If the current count has changed, update the element
        if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
            el.innerHTML = currentCount;
        }

        // If we’ve reached our last frame, stop the animation
        if ( frame === totalFrames ) {
            clearInterval( counter );
        }
    }, frameDuration );
};

if(jQuery('.postid-68').length > 0) {
    
    
    // Progress Rings
    var rings = document.getElementsByClassName("ring-progress");

    for(var i = 0; i < rings.length; i++) {

        var ring = rings[i];

        var id = i.toString();

        window['animated_'+id] = false;

        // console.log(ring);

    }

    function checkScrollRing() {
        
        // console.log('ringscroll');

        var fraction = 0.8; // Play when 80% of the player is visible.

        for(var i = 0; i < rings.length; i++) {
        
            var ring = rings[i];            

            var id = i.toString();

            // console.log(ring);

            if(window['animated_'+id] === false) {

                var x = ring.offsetLeft, 
                    y = ring.offsetTop, 
                    w = ring.offsetWidth, 
                    h = ring.offsetHeight, 
                    
                    visibleX, 
                    visibleY, 
                    visible;

                let r = x + w; //right
                let b = y + h; //bottom
                    

                    visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
                    visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

                    visible = visibleX * visibleY / (w * h);

                    if (visible > fraction) {
                        // console.log('Ring visible');

                        const number = ring.querySelector( '.numbers' );
                        // console.log(number);
                        animateCountUp(number);

                        window['animated_'+id] = true;
                        

                    } else {
                        // console.log('Ring Not visible');
                    }
            }

        }

    }

    window.addEventListener('scroll', checkScrollRing, false);
    window.addEventListener('resize', checkScrollRing, false);
      

}