/*
|==========================================================================
| Initialize Foundation
|==========================================================================
| 
| This initializes Foundation.
|
|==========================================================================
|
*/

jQuery(document).foundation();

jQuery('.values .item > .dropdown-pane').each(function(i){
    var this_element = jQuery(this);
    var aria_labelledby = this_element.data('yeti-box');
    this_element.attr('aria-labelledby',aria_labelledby); 
});