/*
|==========================================================================
| Smooth Scroll
|==========================================================================
| 
| This function adds native smooth scrolling to the project and could
| possibly be removed or improved upon with an updatable package.
|
|==========================================================================
| 
*/

function smooth_scroll() {

	if (window.addEventListener) window.addEventListener('DOMMouseScroll', wheel, false);
	window.onmousewheel = document.onmousewheel = wheel;

	function wheel(event) {
   var delta = 0;
   if (event.wheelDelta) delta = event.wheelDelta / 120;
   else if (event.detail) delta = -event.detail / 3;

   handle(delta);
   if (event.preventDefault) event.preventDefault();
   event.returnValue = false;
 }

}

var goUp = true;
var end = null;
var interval = null;

function handle(delta) {
  var animationInterval = 10; // lower is faster
  var scrollSpeed = 10; // lower is faster

  if (end == null) {
    end = jQuery(window).scrollTop();
  }
  end -= 20 * delta;
  goUp = delta > 0;

  if (interval == null) {
    interval = setInterval(function () {
      var scrollTop = jQuery(window).scrollTop();
      var step = Math.round((end - scrollTop) / scrollSpeed);
      if (scrollTop <= 0 || 
        scrollTop >= jQuery(window).prop("scrollHeight") - jQuery(window).height() ||
        goUp && step > -1 || 
        !goUp && step < 1 ) {
        clearInterval(interval);
      interval = null;
      end = null;
    }
    jQuery(window).scrollTop(scrollTop + step );
  }, animationInterval);
  }
}