/*
|==========================================================================
| Gravity Forms Custom Javascript
|==========================================================================
| 
| These functions interact with the Gravity Forms plugin to add custom
| functionality or remove undesirable features.
|
|==========================================================================
|
*/

function gravity_forms_placeholders() {

	// Clear Gravity Forms placeholder on click
	jQuery('.gform_wrapper ul li input').focus(function() {
		jQuery(this).data('placeholder', jQuery(this).attr('placeholder')).attr('placeholder', '');
	}).blur(function() {
		jQuery(this).attr('placeholder', jQuery(this).data('placeholder'));
	});

}