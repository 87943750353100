var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
];

function dateFormatter(date) {
 var m = monthNames[date.getMonth()];
  // return date.getMonth() + date.getDate();
  return m + ' ' + date.getDate() + ', ' + date.getFullYear();
}

var configProfile = {
	"profile": {"screenName": 'volum8creative'},
	"domId": 'twitter-content',
	"maxTweets": 1,
	"enableLinks": true, 
	"showUser": false,
	"showTime": true,
	"showImages": false,
	"dateFunction": dateFormatter,
	"showInteractionLinks": false,
	"lang": 'en'
};

if (jQuery('#twitter-content').length > 0) {
	twitterFetcher.fetch(configProfile);

	setTimeout(function(){

		var twitter_parent_link_element = jQuery("#twitter-content").parent();
		var tweek_link = jQuery("#twitter-content .timePosted a").attr('href');
		var tweek_link_h3_text = jQuery("#twitter-content .timePosted a").text();
		var tweek_link_h4_text = jQuery("#twitter-content .tweet").text();

		jQuery("#twitter-content .timePosted").text(tweek_link_h3_text);
		jQuery("#twitter-content .tweet").text(tweek_link_h4_text);

		twitter_parent_link_element.attr('href',tweek_link);



	},500);

}

// twitterFetcher.fetch(configProfile);