/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
| 
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

document.addEventListener( "DOMContentLoaded", function() {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect browser and device
  detectBrowserAndDevice();

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {        
    
    sticky_header();

  });

  sticky_header();

  // Progressive Scroll Bar
  if(jQuery('#scrollBar').length > 0) {

    var anchorLink = jQuery('.services-filter ul li a'),
      anchorNav = jQuery('.services-filter ul');

      jQuery(window).scroll(function() {
        var windscroll = jQuery(window).scrollTop();
        if (windscroll >= 1) {
            jQuery('div.service').each(function(i) {
                if (jQuery(this).position().top <= windscroll) {
                  jQuery('ul li a.active').removeClass('active');
                    anchorLink.eq(i);
                }
            });

        } else {
          
          // jQuery(' ul li a.active').removeClass('active');          
        }

    }).scroll();

    jQuery(window).scroll(function () {
      var s = jQuery(window).scrollTop(),
            d = jQuery('#content').height(),
            c = jQuery(window).height();
            scrollPercent = (s / (d-c)) * 100;
            var position = scrollPercent;
    
        jQuery("#scrollBar").attr('style', 'width:' + position + '%');
    
    });

    // jQuery('.services-filter ul li a').on("click", function(f) {
    //   jQuery('.services-filter ul li a.active').removeClass('active');
    //   jQuery(this).addClass('active');
    // });

  }

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  onElementHeightChange(document.body, function () {
      AOS.refresh({
          // disable: 'mobile'
      });
  });


  jQuery(window).resize(function () {
      AOS.refresh({
          // disable: 'mobile'
      });
  });

  // Menu show on delay
  if(jQuery('.animation-wrapper').length > 0 && readCookie('introCompleted') != "true" ) {
    
    // jQuery(window).scrollTop(0);

  } else {

    jQuery('.animation-wrapper').remove();

    jQuery('.off-canvas-wrapper').addClass('animation-in');

    setTimeout(function(){
      jQuery('.open-menu-container').addClass('showmenu');
    },700);

  }

  // Menu animation open and close
  jQuery('#off-canvas').on('opened.zf.offcanvas',function() {
    setTimeout(function(){
      jQuery('.fullpage-menu').show();
      jQuery('#off-canvas .menu').addClass('init-animation');
      jQuery('.about-volum8').addClass('init-animation');
    },500);
    setTimeout(function(){
      jQuery('.close-menu-container').addClass('init-animation');
    },800);
  });

  jQuery('.close-menu').click(function(event) {
    jQuery('.close-menu-container').removeClass('init-animation');
    setTimeout(function(){
      jQuery('.fullpage-menu').hide();
      jQuery('#off-canvas .menu').removeClass('init-animation');
      jQuery('.about-volum8').removeClass('init-animation');
    },250);
    setTimeout(function(){
      jQuery('#off-canvas').foundation('close');
    },400);
  });

  jQuery('#off-canvas .menu-container a, #off-canvas  .v8-logo a').on('click',function(e) {
    if (window.matchMedia("(max-width: 1023px)").matches) {
    } else {
      $link_href = jQuery(this).attr('href'); 
      e.preventDefault();
      jQuery('.close-menu-container').removeClass('init-animation');
      setTimeout(function(){
        jQuery('.fullpage-menu').hide();
        jQuery('#off-canvas .menu').removeClass('init-animation');
        jQuery('.about-volum8').removeClass('init-animation');
      },250);
      setTimeout(function(){
        jQuery('.off-canvas-wrapper').addClass('animation-out');
        jQuery('#off-canvas, .off-canvas-content').addClass('animation-out');
      },400);
      // setTimeout(function(){
      //   jQuery('.off-canvas-wrapper').addClass('animation-out');
      // },600);
      setTimeout(function(){
        window.location.assign($link_href);
      },850);
    }
  });

  // About Page, Values
  jQuery('.item .overlay.show-for-large button').click(function(event) {
    if(jQuery(this).parent().parent().parent().parent().hasClass('open')) {
      jQuery(this).parent().parent().parent().parent().toggleClass('open');
      jQuery(this).find('span.open').toggleClass('hide');
      jQuery(this).find('span.close').toggleClass('hide');
    } else {
      jQuery('.item.open').removeClass('open');
      jQuery('.item.open span.open').toggleClass('hide');
      jQuery('.item.open span.close').toggleClass('hide');
      jQuery(this).parent().parent().parent().parent().toggleClass('open');
      jQuery(this).find('span.open').toggleClass('hide');
      jQuery(this).find('span.close').toggleClass('hide');
    }
  });

  // Homepage, Dan animation
  // if(jQuery('.page-template-template-homepage').length > 0) {
  //   var mouseX;
		
  //   jQuery('.hero').mousemove( function moveFunc(e) {
  //       mouseX = e.clientX;
  //       timV = jQuery("#video").get(0).duration;  
  //       var valV = (timV * mouseX/ jQuery('.hero').width() );
  //         valV = Math.round(valV * 100) / 1000;
  //         jQuery("#video").get(0).currentTime = valV;
  //   });
  // }

  // Reading time for study case
  if(jQuery('.single-projects').length > 0) {
    jQuery('.single-projects').readingTime({
      readingTimeAsNumber: true,
      readingTimeTarget: jQuery('.reading-time'),
      wordsPerMinute: 200,
      round: true,
      lang: 'en',
      success: function(data) {
        // console.log(data);
        if(data.words < 400) {
          jQuery('.reading-time-wrapper').remove();
        }
      },
      error: function(data) {
        // console.log(data.error);
        jQuery('.reading-time-wrapper').remove();
        // $('.reading-time').remove();
      }
    });
  }

  // Reading time for blog
  if(jQuery('.blog article, .category article').length > 0) {
      
      jQuery('article').each(function() {
    
        
          jQuery(this).readingTime({
            readingTimeTarget: jQuery(this).find('.reading-time'),
            remotePath: jQuery(this).data('file'),
            remoteTarget: jQuery(this).data('target'),
            wordsPerMinute: 200,
            round: true,
            lang: 'en',
            readingTimeAsNumber: true,
            success: function(data) {
              // console.log(data);
              
            },
            error: function(data) {
              // console.log(data.error);
              // $('.reading-time').remove();
            }
          });

      });
    
  }
  

  // Reading time for single post
  if(jQuery('.single-post').length > 0) {
    jQuery('.single-post article').readingTime({
      readingTimeAsNumber: true,
      readingTimeTarget: jQuery('.article-header .reading-time'),
      wordsPerMinute: 200,
      round: true,
      lang: 'en',
      success: function(data) {
        // console.log(data);
        
      },
      error: function(data) {
        // console.log(data.error);
        // $('.reading-time').remove();
      }
    });

    jQuery('#volum8-related-posts .article-related').each(function() {
    
        
      jQuery(this).readingTime({
        readingTimeTarget: jQuery(this).find('.reading-time'),
        remotePath: jQuery(this).data('file'),
        remoteTarget: jQuery(this).data('target'),
        wordsPerMinute: 200,
        round: true,
        lang: 'en',
        readingTimeAsNumber: true,
        success: function(data) {
          // console.log(data);
          
        },
        error: function(data) {
          // console.log(data.error);
          // $('.reading-time').remove();
        }
      });

  });

  }
  const speed = 1000;
  
  jQuery('.chapter-filter .menu #select-chapters, .services-filter .menu #select-services').on('change',function() {
    $value = jQuery(this).val();
    jQuery('html, body').animate({ scrollTop: jQuery($value).offset().top - 75 }, speed);
  });

  jQuery('#video-1, #video-2, #video-3, #how-to-mobile').on('open.zf.reveal',function() {
    reveal = jQuery(this);
    // console.log(reveal);
    video = reveal.find('video');
    // console.log(video);
    video.get(0).play();
  });

  // Homepage when video ended show/hide buttons
  if(jQuery('.page-template-template-homepage').length > 0) {
    document.getElementById('video').addEventListener('ended',endVideoCallback,false);
  
    jQuery(' .video-container button[data-play]').click(function(event) {
      jQuery(this).addClass('hide');
      jQuery('.video-container button[data-pause]').removeClass('hide');
      video = jQuery('#video');
      video.get(0).play();
    });
  
    jQuery('.page-template-template-homepage .video-container button[data-pause]').click(function(event) {
      jQuery(this).addClass('hide');
      jQuery('.video-container button[data-play]').removeClass('hide');
      video = jQuery('#video');
      video.get(0).pause();
    });

  }  

});

/*
|==========================================================================
| Use this function to fire events after the entire window has loaded,
| including images and CSS files.
|==========================================================================
*/

window.onload = function() {
  AOS.init({

  // Global settings:
  // disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  // initClassName: 'aos-init', // class applied after initialization
  // animatedClassName: 'aos-animate', // class applied on animation
  // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  // offset: 120, // offset (in px) from the original trigger point
  // delay: 0, // values from 0 to 3000, with step 50ms
  // duration: 400, // values from 0 to 3000, with step 50ms
  // easing: 'ease', // default easing for AOS animations
  // once: false, // whether animation should happen only once - while scrolling down
  // mirror: false, // whether elements should animate out while scrolling past them
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation);
});

window.onbeforeunload = function () {
  if(readCookie('introCompleted') != "true") {
    window.scrollTo(0, 0);
  } 
}

// Intro animation
if(readCookie('introCompleted') != "true" && jQuery('.animation-wrapper').length > 0) {

  if (window.matchMedia("(max-width: 1023px)").matches) {
  } else {

    createCookie('introCompleted', 'true');

    jQuery('.txt-rotate-1').fadeTo( 400, 1 ).fadeTo( 400, 0);

    jQuery('.off-canvas-wrapper').css('opacity', '0');

    setTimeout(function(){

      jQuery('.txt-rotate-2').fadeTo( 400, 1 ).fadeTo( 400, 0);

    },650);

    setTimeout(function(){

      jQuery('.txt-rotate-3').fadeTo( 400, 1 ).fadeTo( 400, 0);

    },1250);

    setTimeout(function(){

      jQuery('.animation-wrapper').addClass('end');

      logo_offset();

    },1950);

  } 

} else {
  jQuery('.animation-wrapper').remove();
}


};

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight
  var newHeight;

  (function run() {
      newHeight = elm.clientHeight;
      if (lastHeight !== newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer) {
          clearTimeout(elm.onElementHeightChangeTimer);
      }

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

function logo_offset() {
  var logo_container = jQuery('.header #v8logo');
  var position = logo_container.offset();			
  if (window.matchMedia("(max-width: 1023px)").matches) {
    
    
  } else {
    jQuery('.animation').addClass('moveup').attr('style','top:'+(position.top - .5)+'px; left:'+(position.left + 127)+'px;');

    setTimeout(function(){
    
      jQuery('.off-canvas-wrapper').css('opacity', '1');
    
    },450);
    
    setTimeout(function(){
    
      jQuery('.animation.moveup').css('opacity', '0');
    
    },1050);

    setTimeout(function(){

      jQuery('.open-menu-container').addClass('showmenu');
    
    },1150);

    setTimeout(function(){
    
      jQuery('.animation.moveup').hide();
    
    },1450);

  }
}

function endVideoCallback(e) {
  jQuery('button[data-play]').removeClass('hide');
  jQuery('button[data-pause]').addClass('hide');
}