/*
|==========================================================================
| jQuery No Conflict Mode
|==========================================================================
| 
| This puts jQuery in "noconflict" mode to play nicely with Foundation
| and WordPress.
|
|==========================================================================
| @reference: https://digwp.com/2011/09/using-instead-of-jquery-in-wordpress/
|
*/

jQuery.noConflict();