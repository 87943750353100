/*
|==========================================================================
| Sticky Header
|==========================================================================
| 
| This function gives us the option to implement a sticky header, with
| custom options for class, height, and calculation.
|
|==========================================================================
|
*/

function check_size() {

    var scroll_pos = jQuery(window).scrollTop();

    

    // if(jQuery('.single-projects').length > 0) {

    //     var height = jQuery( window ).height();

    // } else {

        var height = 76;

    // }

    const mql = window.matchMedia("screen and (max-width: 767px)");

    // console.log(scroll_pos);

    if (scroll_pos > height) {

        jQuery('body header.header').addClass('mini');

        jQuery('.blog-ic').addClass('display');

        if(jQuery('.single-projects').length > 0) {

            jQuery('.single-project-nav').addClass('open');
    
        }

        if(jQuery('.page-template-template-services').length > 0) {

            // jQuery('.top-bar-right').addClass('justify-between-m').addClass('justify-between-l');

            // jQuery('.services-filter .menu').addClass('invisible');

            // jQuery('.services-menu').removeClass('noshow');
    
        }

    } else {

        jQuery('.blog-ic').removeClass('display');

        jQuery('body header.header').removeClass('mini');
        
        if(jQuery('.single-projects').length > 0) {

            jQuery('.single-project-nav').removeClass('open');
    
        }

        if(jQuery('.page-template-template-services').length > 0) {

            // jQuery('.services-filter .menu').removeClass('invisible');

            // jQuery('.services-menu').addClass('noshow');

            // jQuery('.top-bar-right').removeClass('justify-between-m').removeClass('justify-between-l');
    
        }

    }
    

    if (mql.matches || scroll_pos >= height) {
        jQuery('.mobile-menu').removeClass('hide');
    } else {
        jQuery('.mobile-menu').addClass('hide');
    }

    // For blog article
    if(jQuery('.single-post').length > 0) {

        console.log(scroll_pos);

        var byline = jQuery('.byline').offset();

        console.log(byline);

        if (scroll_pos > byline.top) {
            jQuery('.scrollsidebar').removeClass('noshow');
        } else {
            jQuery('.scrollsidebar').addClass('noshow');
        }

    }

}

function sticky_header() {

    check_size();

    jQuery(window).on('resize', function () {
        check_size();
    });
}

