/*
|==========================================================================
| WordPress Custom Read More
|==========================================================================
| 
| This function creates a custom "Read more..." implementation
| within WordPress based on specific classes.
|
|==========================================================================
| @reference: https://medium.com/talk-like/detecting-if-an-element-is-in-the-viewport-jquery-a6a4405a3ea2
|
*/

function readmore() {

	// Read More After a number of characters are shown
	jQuery('.readmore-aboutus').click(function(event){
		jQuery(this).hide();
		jQuery('.hide-paragraph').toggleClass('active');
	});

	// Configure/customize these variables.
	var showChar = 259;  // How many characters are shown by default
	var ellipsestext = "...";
	var moretext = "Read More";
	var lesstext = "Read less";

	jQuery('.aboutus-paragraph .wpb_wrapper').each(function() {

		var content = jQuery(this).html();

		if(content.length > showChar) {

			var c = content.substr(0, showChar);
			var h = content.substr(showChar, content.length - showChar);

			var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span><a href="" class="morelink button">' + moretext + '</a></span>';

			jQuery(this).html(html);

		}

	});

	jQuery(".morelink").click(function(){
		if(jQuery(this).hasClass("less")) {
			jQuery(this).removeClass("less");
			jQuery(this).html(moretext);
		} else {
			jQuery(this).addClass("less");
			jQuery(this).html(lesstext);
		}
		jQuery(this).parent().prev().toggle();
		jQuery(this).prev().find('.morecontent span').toggle();
		jQuery(this).prev().find('.moreellipses').toggle();
		jQuery(this).prev().toggle();
		return false;
	});	

}